import React, { useContext } from 'react';
import ReactGA from 'react-ga';
import { Navbar, Nav, Icon } from 'rsuite';
import { Link } from 'react-router-dom';
import { ThemeContext, Theme } from 'store/ThemeContext';
import classes from './AppBar.module.css';

const AppBar = () => {
  const { theme, switchTheme } = useContext(ThemeContext);
  const themeIcon = theme === Theme.LIGHT ? 'sun-o' : 'moon-o';
  const themeName = theme === Theme.LIGHT ? 'Light' : 'Dark';
  return (
    <Navbar className={classes.Root}>
      <Navbar.Body>
        <Nav>
          <Nav.Item
            componentClass={Link}
            to='/'
            eventKey='1'
            icon={<Icon icon='home' className={classes.Icon} />}>
            <div className={classes.LogoText}>Blackwell's Club</div>
          </Nav.Item>
        </Nav>
        <Nav pullRight>
          <Nav.Item
            eventKey='2'
            icon={<Icon icon={themeIcon} className={classes.Icon} />}
            onClick={switchTheme}>
            <div className={classes.LogoText}>{themeName}</div>
          </Nav.Item>
          <ReactGA.OutboundLink
            eventLabel='appbar_telegram'
            to='https://t.me/blackwells_club'
            target='_blank'
            rel='noopener noreferrer'>
            <Nav.Item
              eventKey='3'
              icon={<Icon icon='telegram' className={classes.Icon} />}>
              <div className={classes.LogoText}>Telegram</div>
            </Nav.Item>
          </ReactGA.OutboundLink>
          <Nav.Item
            componentClass={Link}
            to='/articles/welcome-to-blackwells-club'
            eventKey='4'
            icon={<Icon icon='circle-o' className={classes.Icon} />}>
            <div className={classes.LogoText}>About</div>
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};

export default AppBar;
