import React from 'react';
import { Route, Redirect } from 'react-router';

import { LayoutSwitch } from 'components/navigation';
import Home from 'containers/Home';
import ArticleView from 'containers/ArticleView';
import Page404 from 'containers/Page404';

import classes from './App.module.css';

const App = () => {
  return (
    <div className={classes.Root}>
      <LayoutSwitch>
        <Route path='/' component={Home} exact />
        <Route path='/articles/:id' component={ArticleView} exact />
        <Route path='/404' component={Page404} exact />
        <Redirect to='/404' />
      </LayoutSwitch>
    </div>
  );
};

export default App;
