import React, { useState } from 'react';
import { PanelGroup, Panel, RadioGroup, Radio } from 'rsuite';
import { ArticleType } from 'store/Article';
import SideBarProps from './SideBarProps';
import classes from './SideBar.module.css';

const SideBarMobile = ({ articleType, onSelect }: SideBarProps) => {
  const [isPanelOpen, setPanelOpen] = useState(false);

  const onGroupSelect = (event: ArticleType) => {
    setPanelOpen(false);
    onSelect(event);
  };
  return (
    <div className={classes.SideBarMobile}>
      <PanelGroup
        accordion
        bordered
        className='mobile-dropdown-panel-group'
        onSelect={() => setPanelOpen(!isPanelOpen)}>
        <Panel
          header={articleType}
          expanded={isPanelOpen}
          className='dropdown-panel'>
          <RadioGroup
            value={articleType}
            className='radio-group'
            appearance='picker'
            onChange={onGroupSelect}>
            <Radio value={ArticleType.FEED}>Feed</Radio>
            <Radio value={ArticleType.BOOKS}>Books</Radio>
            <Radio value={ArticleType.MOVIES}>Movies</Radio>
            <Radio value={ArticleType.PODCASTS}>Podcasts</Radio>
            <Radio value={ArticleType.DEVELOPMENT}>Development</Radio>
            <Radio value={ArticleType.ABOUT}>About</Radio>
          </RadioGroup>
        </Panel>
      </PanelGroup>
    </div>
  );
};

export default SideBarMobile;
