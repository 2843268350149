import React, { ReactNode } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Switch } from 'react-router';

import { AppBar, Footer } from 'components/navigation';
import ThemeContextProvider from 'store/ThemeContext';
import ArticleContextProvider from 'store/ArticleContext';
import { ScrollToTop } from 'utils';

const LayoutSwitch = (props: { children: ReactNode }) => (
  <BrowserRouter>
    <ThemeContextProvider>
      <ArticleContextProvider>
        <ScrollToTop />
        <AppBar />
        <Switch>{props.children}</Switch>
        <Footer />
      </ArticleContextProvider>
    </ThemeContextProvider>
  </BrowserRouter>
);

export default LayoutSwitch;
