import React, { ReactNode } from 'react';
import { Button } from 'rsuite';

const ExternalButton = ({
  href,
  children,
}: {
  href: string;
  children: ReactNode;
}) => {
  return (
    <a target='_blank' rel='noopener noreferrer' href={href}>
      <Button appearance='subtle'>{children}</Button>
    </a>
  );
};

export default ExternalButton;
