import React from 'react';
import classes from './UiLoader.module.css';

import { Loader } from 'rsuite';

const UiLoader = () => {
  return (
    <div className={classes.Root}>
      <Loader className={classes.LoaderContainer} size='lg' speed='slow' />
    </div>
  );
};

export default UiLoader;
