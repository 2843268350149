import React, { useState, useEffect, useContext } from 'react';
import { Redirect } from 'react-router';
import ReactHtmlParser from 'react-html-parser';
import ReactGA from 'react-ga';

import { UiLoader } from 'components/ui';
import Article from 'store/Article';
import { ArticleContext } from 'store/ArticleContext';

import classes from './ArticleView.module.css';

const ArticleView = (props: any) => {
  const id: string = props.match.params.id;
  const { getById } = useContext(ArticleContext);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const [article, setArticle] = useState<Article>();
  const [isError, setError] = useState<boolean>(false);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  });

  useEffect(() => {
    const loadArticle = async () => {
      const art = await getById(id);
      if (!art) {
        setError(true);
        return;
      }
      setArticle(art);
    };
    loadArticle();
  }, [getById, id]);

  let image;
  if (article) {
    const imgUrl = article.getHeaderUrl(width > 600 ? 'lg' : 'mb');
    image = (
      <div
        style={{ backgroundImage: `url(${imgUrl})` }}
        className={`${classes.parallax} ${
          article.isImageConain() ? classes.parallaxPodcasts : null
        }`}></div>
    );
  }

  const redirect = isError ? <Redirect to='/404' /> : null;
  const content = article ? (
    <>
      <h1>{article.title}</h1>
      <p className={classes.date}>{article.getFullDate()}</p>
      {ReactHtmlParser(article.body || '')}
    </>
  ) : (
    <UiLoader />
  );
  return (
    <>
      {image}
      <div className={classes.Root}>
        {redirect}
        {content}
      </div>
    </>
  );
};

export default ArticleView;
