import React from 'react';
import classes from './Page404.module.css';

const Page404 = () => {
  return (
    <div className={classes.Root}>
      <div className={classes.base}>
        <img
          alt='lost astronaut'
          className={classes.img}
          src='/lost-astronaut.png'
        />
        <h1>404</h1>
        <h3>We all just lost in space astronauts.</h3>
      </div>
    </div>
  );
};

export default Page404;
