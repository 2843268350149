export interface ArticleJson {
  readonly id: string;
  readonly type: ArticleType;
  readonly title: string;
  readonly preview: string;
  readonly headerExtension: string;
  readonly date: string;
  readonly time: string | null;
}

export enum ArticleType {
  FEED = 'feed',
  BOOKS = 'books',
  MOVIES = 'movies',
  PODCASTS = 'podcasts',
  DEVELOPMENT = 'development',
  ABOUT = 'about',
}

export default class Article implements ArticleJson {
  public readonly id: string;
  public readonly type: ArticleType;
  public readonly title: string;
  public readonly preview: string;
  public readonly headerExtension: string;
  public readonly date: string;
  public readonly time: string | null;
  public readonly isReleased: boolean;

  public body?: string;

  constructor(json: ArticleJson) {
    this.id = json.id;
    this.type = json.type;
    this.title = json.title;
    this.preview = json.preview;
    this.headerExtension = json.headerExtension;
    this.date = json.date;
    this.time = json.time;

    const currentDate = new Date();
    const dateArray: number[] = this.date.split('-').map(i => +i);
    const timeArray: number[] = this.time?.split('-').map(i => +i) || [0, 1];
    const releaseDate = new Date(
      dateArray[0],
      dateArray[1] - 1,
      dateArray[2],
      timeArray[0],
      timeArray[1]
    );
    this.isReleased = releaseDate.getTime() < currentDate.getTime();
  }

  // mb - 720x720 px
  getHeaderUrl(size: 'lg' | 'sm' | 'mb'): string {
    const name =
      this.type === ArticleType.PODCASTS ? 'header.jpg' : `header-${size}.jpg`;
    return `/api/articles/${this.type}/img/${this.date}-${this.id}/${name}`;
  }

  isImageConain(): boolean {
    return this.type === ArticleType.PODCASTS;
  }

  getFileUrl(): string {
    const fileUrl =
      '/api/articles/' + this.type + '/' + this.date + '-' + this.id + '.html';
    return fileUrl;
  }

  getFullDate(): string {
    const date = new Date(this.date);
    const dateArray: Array<string | number> = [
      date.toDateString().split(' ')[2],
      date.toLocaleString('en', { month: 'long' }),
      date.getUTCFullYear(),
    ];
    return dateArray.join(' ');
  }
}
