import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Panel } from 'rsuite';
import Article from 'store/Article';
import classes from './ArticlePanel.module.css';

interface ArticlePanelProps extends RouteComponentProps {
  article: Article;
}

const ArticlePanel = ({ article, history }: ArticlePanelProps) => {
  if (!article.isReleased) return null;
  return (
    <Panel
      className={classes.articlePanel}
      bordered
      onClick={() => history.push(`/articles/${article.id}`)}>
      <img
        src={article.getHeaderUrl('sm')}
        alt={article.title}
        className={classes.img}
      />
      <div className={classes.overlay}>
        <div className={classes.description}>{article.preview}</div>
      </div>
      <h6 className={classes.articlePanelDate}>{article.getFullDate()}</h6>
      <h3 className={classes.articlePanelTitle}>{article.title}</h3>
    </Panel>
  );
};

export default withRouter(ArticlePanel);
