import React, { useState, useEffect } from 'react';
import { Sidenav, Nav, Icon } from 'rsuite';
import { ArticleType } from 'store/Article';
import SideBarProps from './SideBarProps';
import classes from './SideBar.module.css';

const SideBarDesktop = ({ articleType, onSelect }: SideBarProps) => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const updateWindowDimensions = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    updateWindowDimensions();
    window.addEventListener('resize', updateWindowDimensions);

    return () => {
      window.removeEventListener('resize', updateWindowDimensions);
    };
  });

  return (
    <div className={classes.SideBarDesktop}>
      <Sidenav
        expanded={width > 650}
        activeKey={articleType}
        className={classes.sidenav}
        onSelect={onSelect}>
        <Sidenav.Body>
          <Nav>
            <Nav.Item icon={<Icon icon='list' />} eventKey={ArticleType.FEED}>
              Feed
            </Nav.Item>
            <Nav.Item icon={<Icon icon='book' />} eventKey={ArticleType.BOOKS}>
              Books
            </Nav.Item>
            <Nav.Item icon={<Icon icon='film' />} eventKey={ArticleType.MOVIES}>
              Movies
            </Nav.Item>
            <Nav.Item
              icon={<Icon icon='podcast' />}
              eventKey={ArticleType.PODCASTS}>
              Podcasts
            </Nav.Item>
            <Nav.Item
              icon={<Icon icon='desktop' />}
              eventKey={ArticleType.DEVELOPMENT}>
              Development
            </Nav.Item>
            <Nav.Item
              icon={<Icon icon='circle-o' />}
              eventKey={ArticleType.ABOUT}>
              About
            </Nav.Item>
          </Nav>
        </Sidenav.Body>
      </Sidenav>
    </div>
  );
};

export default SideBarDesktop;
