import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Icon, ButtonGroup, Button } from 'rsuite';
import classes from './Footer.module.css';
import pjson from '../../../../package.json';
import ExternalButton from './ExternalButton';

const Footer = (props: RouteComponentProps) => {
  const appearance = 'subtle';
  return (
    <div className={classes.Root}>
      <div className={classes.links}>
        <ButtonGroup vertical>
          <Button
            appearance={appearance}
            active={false}
            onClick={() => props.history.push('/')}>
            home
          </Button>
          <Button
            appearance={appearance}
            active={false}
            onClick={() =>
              props.history.push('/articles/welcome-to-blackwells-club')
            }>
            about
          </Button>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://t.me/blackwells_club'>
            <Button appearance={appearance} active={false}>
              telegram
            </Button>
          </a>
        </ButtonGroup>
      </div>
      <div>
        <p>&copy; 2020 Blackwell's Club. All rights reserved.</p>
        <p>Copying site materials is punishable by law.</p>
        <p>Version: {pjson.version}</p>
      </div>
      <div className={classes.icons}>
        <ButtonGroup>
          <ExternalButton href='https://t.me/dmitryblackwell'>
            <Icon icon='telegram' size='2x' />
          </ExternalButton>
          <ExternalButton href='https://github.com/dmitryblackwell'>
            <Icon icon='github' size='2x' />
          </ExternalButton>
          <ExternalButton href='https://www.linkedin.com/in/dmitryblackwell'>
            <Icon icon='linkedin' size='2x' />
          </ExternalButton>
        </ButtonGroup>
      </div>
    </div>
  );
};

export default withRouter(Footer);
