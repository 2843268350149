import React, { createContext, useState, ReactNode } from 'react';

interface ThemeContextProps {
  theme: Theme;
  switchTheme: Function;
}

export enum Theme {
  LIGHT,
  DARK,
}
export const ThemeContext = createContext<ThemeContextProps>(
  {} as ThemeContextProps
);

const THEME = 'theme';

const ThemeContextProvider = (props: { children: ReactNode }) => {
  const updateThemeUrl = (url: string) => {
    document.getElementById('theme-styles')?.setAttribute('href', url);
  };

  let currentTheme = Theme.LIGHT;
  if (localStorage.getItem(THEME) === Theme.DARK.toString()) {
    currentTheme = Theme.DARK;
    updateThemeUrl('/rsuite/rsuite-dark.min.css');
  }

  const [theme, setTheme] = useState(currentTheme);
  const switchTheme = () => {
    if (theme === Theme.LIGHT) {
      setTheme(Theme.DARK);
      updateThemeUrl('/rsuite/rsuite-dark.min.css');
      localStorage.setItem(THEME, Theme.DARK.toString());
    } else {
      setTheme(Theme.LIGHT);
      updateThemeUrl('/rsuite/rsuite-default.min.css');
      localStorage.setItem(THEME, Theme.LIGHT.toString());
    }
  };

  const value: ThemeContextProps = {
    theme: theme,
    switchTheme: switchTheme,
  };

  return (
    <ThemeContext.Provider value={value}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeContextProvider;
