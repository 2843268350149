import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga';
import ttiPolyfill from 'tti-polyfill';
import { register } from './serviceWorker';
import './index.css';
import App from 'containers/App/';

ReactGA.initialize('UA-176711735-1');

ttiPolyfill.getFirstConsistentlyInteractive().then(tti => {
  if (tti) {
    ReactGA.timing({
      category: 'Performace',
      variable: 'Time to Interactive',
      value: tti,
    });
  }
});

const callback = (list: any) => {
  list.getEntries().forEach((entry: any) => {
    ReactGA.timing({
      category: 'Performace',
      variable: entry.name,
      value: entry.startTime,
    });
  });
};

const observer = new PerformanceObserver(callback);
observer.observe({ entryTypes: ['paint'] });

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
register();
