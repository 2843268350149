import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import ArticlesGroup from 'components/ArticlesGroup';
import { SideBar } from 'components/navigation';
import { ArticleType } from 'store/Article';
import classes from './Home.module.css';

const Home = () => {
  const [currentType, setCurrentType] = useState<ArticleType>(ArticleType.FEED);

  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);

  return (
    <div className={classes.Root}>
      <SideBar onSideBarChange={setCurrentType} />
      <ArticlesGroup currentType={currentType} />
    </div>
  );
};

export default Home;
