import React, { useState, useEffect, useContext } from 'react';
import ArticlePanel from './ArticlePanel';
import Article, { ArticleType } from 'store/Article';
import { Waypoint } from 'react-waypoint';

import classes from './ArticlesGroup.module.css';
import { ArticleContext } from 'store/ArticleContext';

const ArticlesGroup = ({ currentType }: { currentType: ArticleType }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [page, setPage] = useState<number>(0);

  const { getArticles, getArticlesTotalQuantity } = useContext(ArticleContext);

  const loadArticles = async () => {
    if ((await getArticlesTotalQuantity(currentType)) > articles.length) {
      const loadedArticles = await getArticles(currentType, page);
      const newArticles = [...articles, ...loadedArticles];
      setArticles(newArticles);
      setPage(page + 1);
    }
  };

  useEffect(() => {
    setPage(0);
    setArticles([]);
  }, [currentType]);

  return (
    <div className={classes.Articles}>
      <div className={classes.ArticlesPanel}>
        {articles.map(art => (
          <ArticlePanel key={art.id} article={art} />
        ))}
        <Waypoint key={currentType} onEnter={loadArticles} />
      </div>
    </div>
  );
};

export default ArticlesGroup;
