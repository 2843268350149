import React, { useState } from 'react';
import { ArticleType } from 'store/Article';
import SideBarDesktop from './SideBarDesktop';
import SideBarMobile from './SideBarMobile';
import './DropdownPanel.css';

const SideBar = ({
  onSideBarChange,
}: {
  onSideBarChange: (event: ArticleType) => void;
}) => {
  const [articleType, setArticleType] = useState(ArticleType.FEED);

  const onSelect = (event: ArticleType) => {
    setArticleType(event);
    onSideBarChange(event);
  };

  return (
    <>
      <SideBarDesktop articleType={articleType} onSelect={onSelect} />
      <SideBarMobile articleType={articleType} onSelect={onSelect} />
    </>
  );
};

export default SideBar;
