import React, { createContext, ReactNode, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import Article, { ArticleType, ArticleJson } from './Article';

interface ArticleContextProps {
  lastUpdateTime?: Date;
  getArticles: (type: ArticleType, page: number) => Promise<Article[]>;
  getArticlesTotalQuantity: (type: ArticleType) => Promise<number>;
  getById: (id: string) => Promise<Article | undefined>;
}

export const ArticleContext = createContext<ArticleContextProps>(
  {} as ArticleContextProps
);

const ARTICLES_PER_PAGE = 4;

const ArticleContextProvider = (props: { children: ReactNode }) => {
  const [articles, setArticles] = useState<Article[]>([]);
  const [lastUpdateTime, setLastUpdateTime] = useState<Date>();

  const loadArticles = async (): Promise<Article[]> => {
    const axiosResponse: AxiosResponse<ArticleJson[]> = await axios.get(
      '/api/articles/index.json'
    );
    const loadedArticles: Article[] = axiosResponse.data.map(
      (el: ArticleJson) => new Article(el)
    );
    setArticles(loadedArticles);
    setLastUpdateTime(new Date());
    return loadedArticles;
  };

  const getArticlesIfLoaded = async () =>
    articles.length === 0 ? await loadArticles() : articles;

  const getArticles = async (
    type: ArticleType,
    page: number
  ): Promise<Article[]> => {
    const art = await getArticlesIfLoaded();
    return art
      .filter(a => type === ArticleType.FEED || a.type === type)
      .slice(ARTICLES_PER_PAGE * page, ARTICLES_PER_PAGE * (page + 1));
  };

  const getArticlesTotalQuantity = async (type: ArticleType) => {
    const art = await getArticlesIfLoaded();
    return art.filter(a => type === ArticleType.FEED || a.type === type).length;
  };

  const getById = async (id: string): Promise<Article | undefined> => {
    const art = await getArticlesIfLoaded();
    const article = art.find(a => a.id === id);
    if (article && !article.body) {
      const body = await (await fetch(article.getFileUrl())).text();
      article.body = body;
    }
    return article;
  };

  const value: ArticleContextProps = {
    lastUpdateTime,
    getArticles,
    getArticlesTotalQuantity,
    getById,
  };

  return (
    <ArticleContext.Provider value={value}>
      {props.children}
    </ArticleContext.Provider>
  );
};

export default ArticleContextProvider;
